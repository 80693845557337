import React, { useState } from 'react'; 
import { useNavigate } from 'react-router-dom';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const Payments = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    // Validate passwords
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    // Get the payment method from Stripe Elements
    const cardElement = elements.getElement(CardElement);
    const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (paymentMethodError) {
      setError(paymentMethodError.message);
      setLoading(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "Email": email,
      "PaymentMethodId": paymentMethod.id,
      "Password": password
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://17u8c4g7pl.execute-api.us-east-1.amazonaws.com/prod/aicreatecustomer", requestOptions)
      .then(async (response) => {
        const data = JSON.parse(await response.text())
        console.log(data)
        if (data.status != 200) {
          setError(data.error);
          alert(`Failed to create account: ${data.error}`)
        } else {
          const clientSecret = data.clientSecret;
          const { error: confirmError } = await stripe.confirmCardPayment(clientSecret);

          if (confirmError) {
            setError(confirmError.message);
          } else {
            // Successfully subscribed, handle the success response
            alert('Subscription successful! Sending you to log in page');
            navigate('/');
          }
        }
      })
      .catch((error) => {
        console.error(error)
        setError(error)
      });

    // Send the token and email to the server to create a customer and subscription
    // const res = await fetch('https://17u8c4g7pl.execute-api.us-east-1.amazonaws.com/prod/aicreatecustomer', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     Email: email,
    //     PaymentMethodId: paymentMethod.id,
    //   }),
    // });

    // const data = await res.json();

    // if (data.error) {
    //   setError(data.error);
    // } else {
    //   const { clientSecret } = data;
    //   const { error: confirmError } = await stripe.confirmCardPayment(clientSecret);

    //   if (confirmError) {
    //     setError(confirmError.message);
    //   } else {
    //     // Successfully subscribed, handle the success response
    //     alert('Subscription successful! Sending you to log in page');
    //   }
    // }

    setLoading(false);
  };

  return (
    <div className="signup-container">
        <div className="greeting">Sign Up</div>
        <form onSubmit={handleSubmit}>
          <label className="signup-subtext">
            Email
            <input
              className="signup-input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>

          <label className="signup-subtext">
            Password:
            <input
              className="signup-input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>

          <label className="signup-subtext">
            Confirm Password:
            <input
              className="signup-input"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </label>
          <p className="signup-subtext">
            Credit Card Information
          </p>
          <div className="card-element-container">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#a19e95',
                    letterSpacing: '0.05em',
                    padding: '16px',
                    border: '1px solid #555',
                    borderRadius: '4px',
                    backgroundColor: '#393937',
                    fontWeight: 'bold'
                  },
                  invalid: {
                    color: '#fa755a',
                  },
                },
              }}
            />
          </div>
          <button className="signup-submit" type="submit" disabled={!stripe || loading}>
            {loading ? 'Processing...' : 'Create Account'}
          </button>

          {error && <div className="error">{error}</div>}
        </form>
    </div>
  );
};

export default Payments;
