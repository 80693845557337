import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom'; 
import './App.css';
import Chat from './Chat'; 
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payments from './Payments';
import Signin from './Signin';

function App() {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [content, setContent] = useState('');
  const [projects, setProjects] = useState([])
  const roleArnPattern = /^arn:aws:iam::\d{12}:role\/[\w+=,.@-]+$/;
  const textareaRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { email } = location.state || {};
    localStorage.setItem('email', email);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "UserId": email
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://17u8c4g7pl.execute-api.us-east-1.amazonaws.com/prod/aigetcustomer", requestOptions)
      .then(async (response) => {
        const data = JSON.parse(await response.text())
        let newProjects = []
        for(let i in data.body) {
          newProjects.push({
            id: data.body[i]['ProjectId'],
            title: data.body[i]['ProjectName'],
            resources: data.body[i]['Resources'],
            userid: data.body[i]['UserId'],
            rolearn: data.body[i]['RoleArn']         
          })
        }
        console.log(newProjects)
        setProjects(newProjects)
      })
      .catch((error) => {
        console.error(error)
      });
  }, []);

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const cardGroups = chunkArray(projects, 3);

  const handleInputChange = (event) => {
    setContent(event.target.value);

    // Auto-expand the height of the textarea
    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  const handleEnterClick = () => {
    if (!roleArnPattern.test(role)) {
      alert("Please enter a valid IAM role ARN.")
      return;
    }
    if (content.length < 10) {
      alert("Please enter a detailed blurb about what your backend does.")
      return;
    }
    navigate('/chat', { state: { content, role } });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); 
      handleEnterClick(); 
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="greeting">What do you want to build?</div>
        <div className="textarea-container">
          <textarea
            className="large-text-input"
            placeholder="Enter an Admin IAM role ARN"
            value={role}
            onChange={(input) => setRole(input.target.value)}
            rows={1} 
          />
          <textarea
            className="large-text-input"
            placeholder="Enter details about your backend"
            value={content}
            onChange={(input) => handleInputChange(input)}
            ref={textareaRef}
            onKeyDown={(input) => handleKeyDown(input)}  
            rows={4} 
          />
          <button className="enter-button" onClick={() => handleEnterClick()}>
            Enter
          </button>
        </div>

        <div className="textarea-container">
          
        </div>
      </header>
      <div className="subtext-container">
        <i className="fas fa-laptop subtext-icon"></i>
        <div className="subtext">Your projects</div>
      </div>
      {cardGroups.map((group, index) => (
        <div className="cards-section" key={index}>
          {group.map((card) => (
            <div className="card" key={card.id}>
              <i className="fas fa-lightbulb card-icon"></i>
              <div className="card-title">{card.title}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

const AppWrapper = () => {
  const stripePromise = loadStripe('pk_test_51KnZnAJWxXVg2Ha9uJFWzNzEs5YD4eFENGP1CMlDQD6mHK7gYIdQ45fSBC6TKoPkqJYBFhk0hA5Rq0QtVAKU2ceS00YJfhCwrl');
  return (
    <Router>
      <Routes>
        <Route path="/home" element={<App />} /> 
        <Route path="/chat" element={<Chat />} />
        <Route path="/signup" element={<Elements stripe={stripePromise}> <Payments /> </Elements>} />
        <Route path="/" element={<Signin />} />
      </Routes>
    </Router>
  );
};

export default AppWrapper;