import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const firebaseConfig = {
    apiKey: 'AIzaSyCkNeHwQTjs1nynJ14ZuA9-N-iT5Q7OwrM',
    authDomain: 'clara-d34a2.firebaseapp.com',
    projectId: 'clara-d34a2',
    storageBucket: 'clara-d34a2.appspot.com',
    messagingSenderId: '1006210688437',
    appId: 'clara-d34a2'
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem('email', email);
    } catch (err) {
      alert(err.message);
      setLoading(false);
      return
    }

    setLoading(false);
    navigate('/home', { state: { email } });
  };

  return (
    <div className="signup-container">
        <div className="greeting">Sign In</div>
        <form onSubmit={handleSubmit}>
          <label className="signup-subtext">
            Email
            <input
              className="signup-input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>

          <label className="signup-subtext">
            Password:
            <input
              className="signup-input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <button className="signup-submit" type="submit" disabled={loading}>
            {loading ? 'Processing...' : 'Sign In'}
          </button>
        </form>
    </div>
  );
};

export default Signin;
