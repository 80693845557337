import logo from './logo.svg';
import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; 
import './App.css';
// import { MessageSquare } from 'lucide-react';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function waitForFiveSeconds() {
  console.log("Start");

  // Wait for 5 seconds
  await sleep(5000);

  console.log("Waited for 5 seconds");
}

function Chat() {
  const [email, setEmail] = useState('');
  const [content, setContent] = useState('');
  const [resources, setResources] = useState([])
  const [projectName, setProjectName] = useState('')
  const [projectId, setProjectId] = useState('')
  const [role, setRole] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const location = useLocation();
  const projects = [
    { id: 1, title: 'Card 1' },
    { id: 2, title: 'Card 2' },
    { id: 3, title: 'Card 3' },
    { id: 3, title: 'Card 3' },
    { id: 3, title: 'Card 3' }
  ];
  const [messages, setMessages] = useState([])

  useEffect(() => {
    const getEmail = async () => {
      const value = await localStorage.getItem('email');
      setEmail(value)
      return value
    }
    getEmail()
    console.log("email: " + email)
  }, []);

  useEffect(() => {
    const { content, role } = location.state || {};
    setMessages([content])
    setRole(role)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    console.log("email: " + email)
    if(email == "") {
      return
    }
    const raw = JSON.stringify({
      "UserId": email,
      "RoleArn": role,
      "Query": content,
      "ShouldCreate": false
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    fetch("https://6mkkq2xwjztdzi7hbzchz64zrq0hqtnl.lambda-url.us-east-1.on.aws/", requestOptions)
      .then(async (response) => {
        console.log(`Called API with: ${JSON.stringify(requestOptions)}`)
        const data = JSON.parse(await response.text())
        console.log(`API returned with: ${JSON.stringify(data)}`)
        setMessages([...messages, `Here are the resources that will be created, press "Create" to make them. If you want to modify them, enter what you would like to change. ${JSON.stringify(JSON.parse(JSON.stringify(data["resources"])), null, '\t')}`])
        setResources(data["resources"])
        setProjectName(data["projectName"])
        setProjectId(data["projectId"])
      })
      .catch((error) => {
        alert(error)
      });
  }, [email]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value); // Set the selected project
  };

  const handleInputChange = (event) => {
    setContent(event.target.value);

    // Auto-expand the height of the textarea
    textareaRef.current.style.height = 'auto'; // Reset height
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust to content
  };

  const handleCreateClick = () => {
    let newMessages = [...messages, content];
    setMessages(newMessages)
    setContent('')
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    console.log("email: " + email)
    const raw = JSON.stringify({
      "UserId": email,
      "RoleArn": role,
      "Query": content,
      "Resources": resources,
      "ShouldCreate": true,
      "ProjectName": projectName,
      "ProjectId": projectId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    fetch("https://6mkkq2xwjztdzi7hbzchz64zrq0hqtnl.lambda-url.us-east-1.on.aws/", requestOptions)
      .then(async (response) => {
        const data = JSON.parse(await response.text())
        setMessages([...messages, `Done creating resources, please check your AWS account`])
        setResources(data["resources"])
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleEnterClick = () => {
    let newMessages = [...messages, content];
    const query = content
    setMessages(newMessages)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    console.log("email: " + email)
    const raw = JSON.stringify({
      "UserId": email,
      "RoleArn": role,
      "Query": query,
      "Resources": resources,
      "ShouldCreate": false,
      "ProjectName": projectName,
      "ProjectId": projectId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    fetch("https://6mkkq2xwjztdzi7hbzchz64zrq0hqtnl.lambda-url.us-east-1.on.aws/", requestOptions)
      .then(async (response) => {
        setContent('')
        console.log(`Called API with: ${JSON.stringify(requestOptions)}`)
        const data = JSON.parse(await response.text())
        console.log(`API returned with: ${JSON.stringify(data)}`)
        setMessages([...newMessages, `New resources: ${JSON.stringify(JSON.parse(JSON.stringify(data["resources"])), null, '\t')}`])
        setResources(data["resources"])
        console.log(messages)
      })
      .catch((error) => {
        alert(error)
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent the default newline behavior in the textarea
      handleEnterClick(); // Trigger the Enter button click handler
    }
  };

  return (
    <div className="Chat">
      <header className="Chat-header">
        <div className="dropdown-container">
          <div className="dropdown-logo">
          </div>
          {/*<select
            id="project-dropdown"
            value={selectedProject.title}
            onChange={(event) => handleProjectChange(event)}
            className="project-dropdown"
          >
            <option value="">Select a Project</option>
            {projects.map((project, index) => (
              <option key={index} value={project}>
                {project.title}
              </option>
            ))}
          </select>*/}
        </div>
      </header>
      <div className="chat-content">
        <div className="messages-container">
          {messages.map((message, index) => (
            <div key={index} className={`message ${index % 2 === 1 ? 'sent' : 'received'}`} style={{ whiteSpace: 'pre-wrap' }}>
            {message}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        
      </div>
      <div className="text-area-container">
          <textarea
            className="chat-textarea"
            placeholder="Type your message..."
            value={content}
            onChange={(input) => handleInputChange(input)}
            ref={textareaRef}
          />
          <button className="send-button" onClick={handleEnterClick}>
            Send
          </button>
          <button className="send-button" onClick={handleCreateClick}>
            Create
          </button>
        </div>
    </div>
  );
}

export default Chat;
